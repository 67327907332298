import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Banner from "../components/banner"

import { MDXRenderer } from "gatsby-plugin-mdx"

function addDraftBanner(x: boolean) {
  if (x) {
    return <Banner text="This is a draft!" />
  }
  return null
}

export default function MDXTemplate(props: {
  data: {
    mdx: {
      frontmatter: { date: string; path: string; title: string; keywords: string; draft: boolean }
      body: string
    }
  }
}) {
  console.log(props.data)
  const post = props.data.mdx // data.markdownRemark holds our post data
  if (process.env.NODE_ENV === "development") {
    console.log("Frontmatter: " + JSON.stringify(post.frontmatter))
  }
  var keywordsParsed: string[]
  if (post.frontmatter.keywords) {
    keywordsParsed = post.frontmatter.keywords.split(",").map((x) => x.trim())
  } else {
    keywordsParsed = []
  }
  return (
    <>
      <SEO title={post.frontmatter.title} keywords={keywordsParsed} />
      <Layout
        preContentBanners={addDraftBanner(post.frontmatter.draft)}
        renderName={false}
        allowExtraWide={false}
      >
        <div className="center mw8">
          <Helmet title={`${post.frontmatter.title}`} />
          <article className="pa3 mw6 mw9-ns center">
            <h1 className="f3 f2-m f1-l">{post.frontmatter.title}</h1>
            <h4 className="">{post?.frontmatter?.date}</h4>
            <div className="lh-copy allow-fullwidth-image">
              <MDXRenderer>{post.body}</MDXRenderer>
            </div>
          </article>
        </div>
      </Layout>
    </>
  )
  //   return <p>Hi</p>
}

// export default function MDXTemplate(data: {
//   html: string
//   frontmatter: { date: string; path: string; title: string; keywords: [string]; draft: boolean }
// }) {
//   return Template({ data })
// }

export const pageQuery = graphql`
  query MDXBlogPostByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        keywords
        draft
      }
    }
  }
`
